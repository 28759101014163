import { defineStore } from 'pinia'

export const useUserStore = defineStore({
  
  id: 'user-store',
  persist: true,

  state: () => {
    return {
      id: null,
      profile: null,
      notifications: 0
    }
  },

  actions: {

    setProfile(profile) {
      this.profile = profile
    },

    signIn(id) {
      this.id = id
    },

    signOut() {
      this.profile = null
      this.id = null
      this.notifications = 0
    }

  }
  
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}